import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../../hooks/useLogin'

export const Header = ({}: any) => {
  const { loggedIn, logout } = useContext(UserContext)

  return (
    <header className="py-4">
      <div className="container mx-auto px-2 flex justify-between items-center">
        <h1 className="text-3xl text-blue-400 font-bold">
          <Link to="/">🚘 Whip Social</Link>
          <span className="text-sm ml-1 text-blue-300">YOHB?</span>
        </h1>
        {loggedIn ? (
          <p>
            <Link to="/garage">Garage</Link>
            <span className="px-2">|</span>
            <Link to="/" onClick={logout}>
              Logout
            </Link>
          </p>
        ) : (
          <ul className="flex">
            <li className="mr-4">
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link
                to="/join"
                className="py-2 px-4 bg-blue-400 text-white font-bold rounded-lg"
              >
                Sign Up
              </Link>
            </li>
          </ul>
        )}
      </div>
    </header>
  )
}
