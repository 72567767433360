import React from 'react'
import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps,
  useLocation,
} from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import UserProfile from './views/UserProfile'
import WhipProfile from './views/whips/WhipProfile'
import Login from './views/Login'
import Home from './views/Home'
import Garage from './views/Garage'
import { Header } from './views/partials/Header'
import useLogin, { UserContext } from './hooks/useLogin'
import SignUp from './views/SignUp'
import { PageLayout } from './views/partials/PageLayout'
import NewWhip from './views/whips/NewWhip'

const RouteWithLayout = (props: RouteProps) => (
  <PageLayout>
    <Route {...props} />
  </PageLayout>
)

function AppSwitch() {
  const location = useLocation()

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Switch location={location}>
          <RouteWithLayout path="/login">
            <Login />
          </RouteWithLayout>
          <RouteWithLayout path="/logged-in">
            <Login />
          </RouteWithLayout>
          <RouteWithLayout path={['/whips/new', '/whips/:whipId']}>
            <WhipProfile />
          </RouteWithLayout>
          <RouteWithLayout path="/users/:username">
            <UserProfile />
          </RouteWithLayout>
          <RouteWithLayout path={['/garage', '/garage/:whipId']}>
            <Garage />
          </RouteWithLayout>
          <RouteWithLayout path="/join">
            <SignUp />
          </RouteWithLayout>
          <RouteWithLayout path="/">
            <Home />
          </RouteWithLayout>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

function App() {
  const { ...allLogin } = useLogin()

  return (
    <div className="App">
      <UserContext.Provider value={{ ...allLogin }}>
        <Router>
          <Header />
          <AppSwitch />
        </Router>
      </UserContext.Provider>
    </div>
  )
}

export default App
