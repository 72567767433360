import { ChangeEvent, PropsWithChildren } from 'react'
import tw from 'twin.macro'

export const StyledInput = tw.input`block py-1 px-2 md:w-3/4 rounded bg-gray-100`

export const LabelWrapper = tw.div`flex flex-col mb-4 max-w-sm md:flex-row md:items-center`

export const LabelText = tw.span`block text-sm md:text-base`

export const StyledLabel = tw(
  LabelText,
)`ml-1 md:ml-0 md:pr-2 mb-1 md:mb-0 md:w-1/4`

export default function Input({
  label,
  ...props
}: {
  label: string
  onChange: ChangeEvent<HTMLInputElement>
} & PropsWithChildren<any>) {
  return (
    <LabelWrapper>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledInput {...props} />
    </LabelWrapper>
  )
}

export const InputWithLabel = ({
  label,
  children,
}: PropsWithChildren<{ label: string }>) => (
  <LabelWrapper>
    {label && <StyledLabel>{label}</StyledLabel>}
    {children}
  </LabelWrapper>
)
