import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { api } from '../api'

export const Home = () => {
  const [whips, setWhips] = useState([])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await api.get('whips')
        setWhips(data)
      } catch (error) {}
    })()
  }, [])

  return (
    <div className="max-w-3xl mx-auto">
      <div className="border-2 rounded p-2 border-blue-600 bg-blue-300">
        Yo, this is super beta. You may lose your account, so don't get too
        attached.
      </div>
      <ul>
        {whips.map(({ id, name }) => (
          <li key={id}>
            <Link to={`/whips/${id}`}>
              {name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Home
