import { PropsWithChildren } from 'react'
import ReactModal from 'react-modal'

export default function Modal({
  children,
  ...props
}: PropsWithChildren<ReactModal.Props>) {
  return (
    <ReactModal
      {...props}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full"
      overlayClassName="fixed inset-0 bg-black-transparent"
    >
      <div className="container mx-auto px-4 md:max-w-2xl">{children}</div>
    </ReactModal>
  )
}
