import React, { PropsWithChildren } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Error({ children, onDismiss }: PropsWithChildren<any>) {
  return (
    <div className="bg-red-600 text-white font-bold rounded-lg p-4 mb-4 flex items-center justify-between">
      <FontAwesomeIcon icon="exclamation-circle"></FontAwesomeIcon>
      <div>{children}</div>
      <FontAwesomeIcon
        icon="times"
        onClick={() => onDismiss(children)}
      ></FontAwesomeIcon>
    </div>
  )
}
