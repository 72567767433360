import React from 'react'
import classNames from 'classnames'
import tw from 'twin.macro'
import { CarColor } from '../../types/CarColor'

export type WhipCardSizes = 'sm' | 'default' | 'lg'

export interface WhipCardProps {
  name?: string
  size?: WhipCardSizes
  username?: string
  details?: {
    make?: string
    model?: string
  }
  color?: CarColor
  imageUrl?: string
}

export const Card = tw.article`p-2 mb-4 inline-flex font-sans rounded-md shadow-md overflow-hidden`

export const ListCard = tw.article`p-2 mb-4 flex font-sans rounded-md shadow-md overflow-hidden`

export const WhipCard = ({
  name,
  username,
  details,
  color,
  imageUrl,
  size = 'default',
  ...props
}: WhipCardProps) => {
  const cardSizeClasses = (size: string) => {
    const sizeMap: Record<string, string> = {
      sm: `h-28 w-28`,
      default: `h-32 w-32`,
      lg: `h-44 w-44`,
    }

    return sizeMap[size]
  }

  return (
    <Card
      {...props}
      className={classNames(`relative bg-cover`, cardSizeClasses(size), {
        'p-2': size === 'sm',
        'p-3': ['default', 'lg'].includes(size),
      })}
      style={{
        backgroundColor: `#${color?.hex ?? 'eee'}`,
        ...(imageUrl && { backgroundImage: `url(${imageUrl})` }),
      }}
    >
      <div className={`flex flex-col`}>
        <p
          className={classNames(
            'relative z-10 font-bold text-white text-shadow-md',
          )}
        >
          <span
            className={classNames(`block leading-none`, {
              'text-sm tracking-tight': size === 'sm',
              'text-base': ['default'].includes(size),
              'text-lg': ['lg'].includes(size),
            })}
          >
            {name || username}
          </span>
          <span
            className={classNames('font-normal', {
              'text-sm tracking-tighter': size === 'sm',
              'text-base tracking-tight': ['default', 'lg'].includes(size),
            })}
          >
            {details?.make} {details?.model}
          </span>
        </p>
        <div className="absolute top-0 left-0 right-0 h-20 bg-gradient-to-b from-black-transparent to-transparent"></div>
      </div>
    </Card>
  )
}

export default Card
