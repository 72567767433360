import { ChangeEvent, PropsWithChildren } from 'react'
import tw from 'twin.macro'
import { LabelText, LabelWrapper, StyledLabel } from './Input'

export const StyledTextArea = tw.textarea`block py-1 px-2 mb-4 w-full rounded bg-gray-100`

export default function TextArea({
  label,
  ...props
}: {
  label: string
  onChange: ChangeEvent<HTMLInputElement>
} & PropsWithChildren<any>) {
  return (
    <>
      <>
        <LabelText className="mb-2">{label}</LabelText>
        <StyledTextArea {...props} />
      </>
    </>
  )
}
