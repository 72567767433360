import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { api } from '../api'

export default function UserProfile() {
  const { username } = useParams<{ username: string }>()
  const [user, setUser] = useState<any>()

  const createWhip = async () => {
    try {
      const { data } = await api.post(`whips`, {
        name: `Another Whip`,
        userId: user.id,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const deleteWhip = (id: number) => async () => {
    const { data } = await api.delete(`whips/${id}`)
  }

  useEffect(() => {
    api.get(`users/${username}`).then(({ data: user }: any) => {
      console.log({ user })
      setUser(user)
    })
  }, [])

  return (
    <article className="max-w-3xl mx-auto my-8 px-4">
      <header className="mb-16">
        <div className="max-w-3xl mx-auto">
          <Link to={`/users/${user?.username}`}>
            <h1 className="text-2xl font-bold leading-tight">
              @{user?.username}
            </h1>
          </Link>
          <p className="text-gray-700 mb-2">
            {user?.firstName} {user?.lastName}
          </p>
          <button className="text-black bg-gray-100">Edit Profile</button>
        </div>
      </header>
      <section>
        <header className="flex mb-4">
          <h3 className="text-xl font-bold mr-2">Your Garage</h3>
          <button
            className="bg-green-300 text-gray-700 flex items-center"
            onClick={createWhip}
          >
            <span className="mr-3">➕🚘</span>
            <span>Add Whip</span>
          </button>
        </header>
      </section>
      <ul className="">
        {user?.whips?.map(({ id, name }: any, i: number) => (
          <li
            key={id}
            className={classNames(
              'flex justify-between py-2 px-2 rounded-sm hover:bg-gray-200',
              i % 2 && 'bg-gray-100',
            )}
          >
            <Link to={`/whips/${id}`}>{name}</Link>
            <button className="bg-red-400" onClick={deleteWhip(id)}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </article>
  )
}
