import { gql } from '@apollo/client'

export const GET_LOGGED_IN_USER_QUERY = gql`
  query LoggedInUser {
    loggedInUser {
      id
      username
      firstName
      lastName
      whips {
        id
        name
        details {
          make
          model
          year
        }
      }
      whipHistory {
        id
        title
        description
        miles
        createdAt
        updatedAt
        whip {
          id
          name
          color {
            hex
          }
        }
      }
    }
  }
`

export const FIND_WHIP = gql`
  query FindWhip($id: Int!) {
    whip(id: $id) {
      id
      name
      history {
        id
        title
        description
        miles
        createdAt
        updatedAt
        whip {
          id
          name
          color {
            hex
          }
        }
      }
      details {
        make
        model
        year
      }
      color {
        hex
      }
      user {
        username
      }
    }
  }
`

export const GET_WHIP_HISTORY_BY_WHIP_QUERY = gql`
  query WhipHistoryByWhips($whipIds: [Int!]) {
    whipHistory(whipIds: $whipIds) {
      id
      title
      description
      miles
      createdAt
      updatedAt
      whip {
        id
        name
        color {
          hex
        }
      }
    }
  }
`

export const CREATE_WHIP_HISTORY_MUTATION = gql`
  mutation CreateWhipHistory($whipHistory: CreateWhipHistoryInput!) {
    createWhipHistory(whipHistory: $whipHistory) {
      id
    }
  }
`

export const REMOVE_WHIP_HISTORY_MUTATION = gql`
  mutation RemoveWhipHistory($id: Int!) {
    removeWhipHistory(id: $id) {
      title
    }
  }
`
