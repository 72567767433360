import React, { PropsWithChildren } from 'react'

export default function PageTitle({
  noBottomMargin = false,
  children,
  title,
  subtitle,
}: PropsWithChildren<{
  noBottomMargin?: boolean
  title?: string
  subtitle?: string
}>) {
  return (
    <h1
      className={`font-bold text-3xl text-gray-700 mr-3 ${
        noBottomMargin ? 'mb-0' : 'mb-4'
      }`}
    >
      {title ? (
        <>
          <span className="mr-1">{title}</span>
          {subtitle && (
            <span className="font-bold text-sm text-gray-500 uppercase">
              {subtitle}
            </span>
          )}
        </>
      ) : (
        children
      )}
    </h1>
  )
}
