import ReactSelect, { Props } from 'react-select'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config'

const config = resolveConfig(tailwindConfig)

export type { ValueType } from 'react-select'

export default function Select(props: Props) {
  return (
    <ReactSelect
      styles={{
        container: (provided) => ({
          ...provided,
          width: '100%',
        }),
        control: (provided) => ({
          ...provided,
          border: 'none',
          backgroundColor: config.theme.backgroundColor.gray['100'],
        }),
      }}
      {...props}
    />
  )
}
