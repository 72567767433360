import React, { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import AddWhipHistoryModalButton from './whips/AddWhipHistoryDialog'
import { LinkButton } from '../component/Button'
import { WhipCard } from '../component/Card'
import { Heading } from '../component/Heading'
import PageTitle from '../component/PageTitle'
import { UserContext } from '../hooks/useLogin'
import WhipHistoryList from './whips/WhipHistoryList'

export default function Garage() {
  const {
    user: { id: userId, whips, whipHistory },
    loadingUser,
  } = useContext(UserContext)
  const { whipId } = useParams<{ whipId: string }>()

  console.log({ whipHistory, loadingUser })

  return (
    <>
      <header className="flex items-center justify-between mb-4">
        <PageTitle noBottomMargin>Your Garage</PageTitle>
        <div className="grid grid-cols-2 gap-2">
          <LinkButton to="/whips/new">
            <span className="mr-3">➕🚘</span>
            <span>New Whip</span>
          </LinkButton>
          <AddWhipHistoryModalButton />
        </div>
      </header>
      <section className="mb-8">
        <Heading>Whips</Heading>
        <ul className="flex -mx-2">
          {whips?.map(({ id, ...whip }: any, i: number) => (
            <li key={id} className="mx-2 rounded-sm items-center">
              <Link to={`/whips/${id}`}>
                <WhipCard {...whip} />
              </Link>
            </li>
          ))}
        </ul>
      </section>

      <WhipHistoryList loading={loadingUser} data={whipHistory} />
    </>
  )
}
