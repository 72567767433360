import React, { useState, useEffect, useContext, ChangeEvent } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Button, LinkButton } from '../component/Button'
import Input from '../component/Input'
import { UserContext } from '../hooks/useLogin'

export const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()
  const { loginWithPassword, loginWithToken, loggedIn, logout } = useContext(
    UserContext,
  )
  const location = useLocation()

  useEffect(() => {
    ;(async () => {
      if (!location.search.split('?')[1]) return

      const queryParams = location.search
        .split('?')[1]
        .split('&')
        .reduce((acc, param) => {
          const [key, value] = param.split('=')

          if (!acc[key]) acc[key] = value

          return acc
        }, {} as Record<string, string>)

      if (queryParams) loginWithToken(queryParams?.access_token)
    })()
  }, [location])

  useEffect(() => {
    if (loggedIn) history.push('/garage')
  }, [loggedIn])

  return (
    <article className="container mx-auto my-8 px-4">
      {loggedIn ? (
        <div>
          <p>Logged in.</p>
          <button className="btn bg-gray-400" onClick={logout}>
            Log Out
          </button>
        </div>
      ) : (
        <div>
          <form
            method="post"
            className="mb-4"
            onSubmit={async (event) => {
              event.preventDefault()
              await loginWithPassword(username, password)
              history.push('/')
            }}
          >
            <Input
              type="text"
              label="Username"
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLInputElement>) => setUsername(value)}
            />
            <Input
              type="password"
              label="Password"
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLInputElement>) => setPassword(value)}
            />
            <Button className="btn" type="submit">
              Login
            </Button>
            <LinkButton
              to={`${process.env.REACT_APP_API_BASE_URL}/auth/facebook`}
            >
              Login with Facebook
            </LinkButton>
          </form>
        </div>
      )}
    </article>
  )
}

export default Login
