import React, { ChangeEvent, FC, useContext, useState } from 'react'
import classNames from 'classnames'
import { useForm, Controller } from 'react-hook-form'
import Select, { ValueType } from '../../component/Select'
import { Button, DeleteButton } from '../../component/Button'
import TextArea from '../../component/TextArea'
import Input, { InputWithLabel } from '../../component/Input'
import { UserContext } from '../../hooks/useLogin'
import { api } from '../../api'
import { Whip, WhipHistory } from '../../types/Whip'
import Modal from '../../component/Modal'
import {
  CREATE_WHIP_HISTORY_MUTATION,
  GET_LOGGED_IN_USER_QUERY,
  REMOVE_WHIP_HISTORY_MUTATION,
} from '../../queries'
import { useMutation } from '@apollo/client'

export interface WhipHistoryDialogProps {}

export const AddWhipHistory = ({}: WhipHistoryDialogProps) => {
  const [showModal, setShowModal] = useState(false)

  const onClose = () => setShowModal(false)

  return (
    <>
      <AddHistoryButton onClick={() => setShowModal(true)} />
      <Modal isOpen={showModal} onRequestClose={onClose}>
        <div className={classNames('bg-white p-4 rounded shadow')}>
          <AddWhipHistoryForm onClose={onClose} className="mb-0" />
        </div>
      </Modal>
    </>
  )
}

export const EditWhipHistory = ({
  whipHistory,
  ModalTrigger,
}: {
  ModalTrigger: ({ onClick }: { onClick: () => void }) => any
  whipHistory: Partial<WhipHistory>
}) => {
  const [showModal, setShowModal] = useState(false)

  const onClose = () => setShowModal(false)

  return (
    <>
      <ModalTrigger onClick={() => setShowModal(true)} />
      <Modal isOpen={showModal} onRequestClose={onClose}>
        <div className={classNames('bg-white p-4 rounded shadow')}>
          <AddWhipHistoryForm
            onClose={onClose}
            className="mb-0"
            {...whipHistory}
          />
        </div>
      </Modal>
    </>
  )
}

export const AddWhipHistoryForm: FC<
  Partial<WhipHistory> & { className?: string; onClose: () => void }
> = ({ id, title, description, miles, whip, onClose, className = 'mb-8' }) => {
  const {
    user: { id: userId, whips },
    accessToken,
  }: any = useContext(UserContext)
  const [createWhipHistoryMutation] = useMutation(
    CREATE_WHIP_HISTORY_MUTATION,
    {
      refetchQueries: [{ query: GET_LOGGED_IN_USER_QUERY }],
    },
  )
  const [removeWhipHistoryMutation] = useMutation(
    REMOVE_WHIP_HISTORY_MUTATION,
    {
      refetchQueries: [{ query: GET_LOGGED_IN_USER_QUERY }],
    },
  )

  const createWhipHistory = async ({
    whipId,
    title,
    description,
    miles,
  }: any) => {
    await createWhipHistoryMutation({
      variables: {
        whipHistory: {
          userId,
          whipId,
          title,
          description,
          miles: miles ? +miles : null,
        },
      },
    })

    onClose()
  }

  const editWhipHistory = async ({ whipId, description, miles }: any) => {
    const { data } = await api.patch(
      `whip-history/${id}`,
      {
        userId,
        whipId,
        description,
        miles: miles ? +miles : null,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    )

    onClose()
  }

  const removeWhipHistory = async (id: number) => {
    await removeWhipHistoryMutation({
      variables: {
        id,
      },
    })

    onClose()
  }

  const { control, handleSubmit } = useForm()

  return (
    <form
      onSubmit={handleSubmit(id ? editWhipHistory : createWhipHistory)}
      className={className}
    >
      <h2 className="text-lg font-bold mb-2">
        {id ? 'Edit' : 'New'} Vehicle History
      </h2>
      <div className="md:grid grid-cols-2 gap-4">
        <Controller
          name="whipId"
          control={control}
          defaultValue={{ label: whip?.name, value: whip?.id }}
          render={({ field: { onBlur, onChange, value, ref } }) => (
            <InputWithLabel label="Whip">
              <Select
                placeholder="Choose Whip..."
                options={whips.map(({ id, name }: Whip) => ({
                  label: name,
                  value: id,
                }))}
                hasValue
                defaultValue={value}
                onChange={(
                  selectedOption: ValueType<
                    {
                      label: string
                      value: number | string
                    },
                    false
                  >,
                ) => onChange(selectedOption?.value)}
              />
            </InputWithLabel>
          )}
        />
        <Controller
          name="miles"
          control={control}
          defaultValue={miles ?? ''}
          render={({ field: { onChange, value } }) => (
            <Input
              label="Miles"
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLInputElement>) => onChange(value)}
              value={value}
            />
          )}
        />
        <Controller
          name="title"
          control={control}
          defaultValue={title}
          render={({ field: { onChange, value } }) => (
            <Input
              label="Title"
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLInputElement>) => onChange(value)}
              value={value}
            />
          )}
        />
      </div>
      <Controller
        name="description"
        control={control}
        defaultValue={description}
        render={({ field: { onChange, value } }) => (
          <TextArea
            placeholder="What was done?"
            label="Description"
            onChange={onChange}
            value={value}
          />
        )}
      />

      {id ? (
        <>
          <Button className="mr-2" type="submit">
            Save
          </Button>
          <DeleteButton
            onClick={(event) => {
              event.preventDefault()
              removeWhipHistory(id)
            }}
          >
            Delete
          </DeleteButton>
        </>
      ) : (
        <Button type="submit">Add History</Button>
      )}
    </form>
  )
}

export const AddHistoryButton = ({ onClick }: { onClick: () => void }) => (
  <Button onClick={onClick}>
    <span className="mr-3">➕📝</span>
    <span>Add History</span>
  </Button>
)

export default AddWhipHistory
