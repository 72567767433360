import React, { useEffect } from 'react'
import Error from './Error'

export default function Notifications({ errors }: { errors: string[] }) {
  const [notifications, setNotifications] = React.useState<
    { type: string; message: string; dismissed: boolean }[]
  >([])

  const dismissNotification = (message: string) => {
    console.log(message)
    setNotifications(
      notifications.map((notification) =>
        message === notification.message
          ? {
              ...notification,
              dismissed: true,
            }
          : notification,
      ),
    )
  }

  useEffect(() => {
    setNotifications(
      errors.map((error) => ({
        type: 'error',
        message: error,
        dismissed: false,
      })),
    )
  }, [errors])

  return (
    <div>
      {notifications &&
        notifications.map(
          ({ message, dismissed }: { message: string; dismissed: boolean }) =>
            !dismissed && (
              <Error onDismiss={dismissNotification}>{message}</Error>
            ),
        )}
    </div>
  )
}
