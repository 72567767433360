import React, { ChangeEvent, useState } from 'react'
import { useHistory } from 'react-router'
import Input from '../component/Input'
import { api } from '../api'
import { AxiosError, AxiosResponse } from 'axios'
import Notifications from '../component/Notifications'

export default function SignUp() {
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [errors, setErrors] = useState([])
  const history = useHistory()

  return (
    <section className="mx-auto max-w-3xl">
      <p>You on here, bro?</p>
      <h1 className="text-3xl font-bold mb-4">Sign Up</h1>
      <Notifications errors={errors} />
      <form
        method="post"
        className="mb-4"
        onSubmit={(event) => {
          event.preventDefault()
          api
            .post('users', {
              username,
              email,
              password,
              firstName,
              lastName,
            })
            .then((response: AxiosResponse) => {
              console.log(response)
              setErrors([])
              history.push('/profile')
            })
            .catch((error: AxiosError) => {
              const errorMessages = error.response?.data.message
              setErrors(errorMessages.length ? errorMessages : [])
            })
        }}
      >
        <Input
          type="text"
          label="Username"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            setUsername(value)
          }
          value={username}
          required
        />
        <Input
          type="email"
          label="Email"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            setEmail(value)
          }
          value={email}
          required
        />
        <Input
          type="password"
          label="Password"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            setPassword(value)
          }
          value={password}
          required
        />
        <Input
          type="text"
          label="First Name"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            setFirstName(value)
          }
          value={firstName}
        />
        <Input
          type="text"
          label="Last Name"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            setLastName(value)
          }
          value={lastName}
        />
        <button className="btn" type="submit">
          Sign Up
        </button>
      </form>
    </section>
  )
}
