import { FC } from 'react'
import { Heading } from '../../component/Heading'
import { WhipHistory } from '../../types/Whip'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import WhipHistoryListItem from './WhipHistoryListItem'

export interface WhipHistoryListProps {
  data?: WhipHistory[]
  loading?: boolean
}

export const WhipHistoryList: FC<WhipHistoryListProps> = ({
  data,
  loading = undefined,
}) => (
  <section>
    <Heading>Whip History</Heading>
    {loading || typeof loading === 'undefined' ? (
      <div>Loading</div>
    ) : (
      <TransitionGroup>
        {data?.length ? (
          data.map((whipHistory: WhipHistory) => (
            <CSSTransition key={whipHistory.id} classNames="fade" timeout={300}>
              <WhipHistoryListItem {...whipHistory}></WhipHistoryListItem>
            </CSSTransition>
          ))
        ) : (
          <CSSTransition key={`none`} classNames="fade" timeout={300}>
            <p className="italic mx-auto">No whip history.</p>
          </CSSTransition>
        )}
      </TransitionGroup>
    )}
  </section>
)

export default WhipHistoryList
