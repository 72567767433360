import { FC } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import { EditWhipHistory } from './AddWhipHistoryDialog'
import { Avatar } from '../../component/Avatar'
import { WhipHistory } from '../../types/Whip'
import { timestampToPrettyDate } from '../../util/dates'

export const WhipHistoryListItem: FC<Partial<WhipHistory> & { to?: string }> = (
  whipHistory,
) => (
  <article className="p-2 mb-2 flex font-sans rounded-md shadow overflow-hidden">
    <div className="flex items-center w-full">
      {whipHistory.whip && <Avatar color={whipHistory.whip?.color} />}
      <div className="ml-4 flex-shrink-0">
        <time className="block text-sm">
          {whipHistory.updatedAt &&
            timestampToPrettyDate(whipHistory.updatedAt)}
        </time>
        <span className="block h-5 text-sm">
          {whipHistory.miles && `${whipHistory.miles} mi`}
        </span>
      </div>
      <div className="flex flex-grow items-center justify-between ml-4">
        <div className="flex-grow">
          <span>
            <Link
              to={whipHistory.to || `/whips/${whipHistory?.whip?.id}`}
              className="font-bold text-gray-500 leading-tight text-sm"
            >
              {whipHistory.whip?.name}
            </Link>
          </span>
          <div
            className={classNames('flex', {
              'flex-col': !whipHistory.whip?.name,
            })}
          >
            <span
              className={classNames({
                'mr-1 font-semibold': whipHistory.whip,
                'font-bold': !whipHistory.whip,
              })}
            >
              {whipHistory.title}
            </span>
            <span
              className={classNames('col-span-full', {
                'text-gray-500': whipHistory.whip,
                'text-sm': !whipHistory.whip,
              })}
            >
              {whipHistory?.description}
            </span>
          </div>
        </div>
        <EditWhipHistory
          whipHistory={whipHistory}
          ModalTrigger={({ onClick }) => (
            <FontAwesomeIcon
              className={classNames(
                `cursor-pointer text-gray-300 hover:text-green-400`,
              )}
              icon="edit"
              onClick={onClick}
            />
          )}
        />
      </div>
    </div>
  </article>
)

export default WhipHistoryListItem
