import { CarColor } from '../types/CarColor'

export interface AvatarProps {
  color?: CarColor
  imageUrl?: any
}

export const Avatar = ({ color, imageUrl }: AvatarProps) => (
  <div
    className="w-12 h-12 flex-shrink-0 rounded-full"
    style={{ backgroundColor: `#${color?.hex}` }}
  ></div>
)
