import React, { PropsWithChildren, ReactNode } from 'react'

export function PageLayout({
  children,
}: PropsWithChildren<{ children: ReactNode }>) {
  return (
    <div className="absolute left-0 right-0 bg-white container md:max-w-3xl mx-auto p-4">
      {children}
    </div>
  )
}
