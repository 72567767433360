import { useQuery } from '@apollo/client'
import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { api } from '../../api'
import { Button } from '../../component/Button'
import { UserContext } from '../../hooks/useLogin'
import { FIND_WHIP } from '../../queries'
import { WhipHistory } from '../../types/Whip'
import WhipHistoryList from './WhipHistoryList'

export const WhipProfile = () => {
  const { whipId }: any = useParams()
  const { accessToken } = useContext(UserContext)
  const [editedWhip, setEditedWhip] = useState<any>([])
  const [editing, setEditing] = useState<boolean>(false)
  const { data, loading, error } = useQuery(FIND_WHIP, {
    variables: { id: +whipId },
  })

  const updateWhip = async () => {
    try {
      const { data } = await api.patch(`whips/${whipId}`, editedWhip, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    data?.whip && setEditedWhip(data?.whip)
  }, [data])

  console.log({ user: data?.user })

  return (
    <>
      <article className="">
        <header className="mb-4">
          <div className="max-w-3xl mx-auto">
            {editing ? (
              <>
                <div className="flex">
                  <button
                    className="text-black block mb-4 bg-blue-400 mr-3"
                    onClick={() => {
                      updateWhip()
                      setEditing(false)
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="text-black block mb-4 bg-gray-400"
                    onClick={() => setEditing(false)}
                  >
                    Cancel
                  </button>
                </div>
                <span className="leading-tight">@{data?.user?.username}</span>
                <input
                  className="block text-2xl font-bold border-b-2 border-gray-400 mb-4"
                  onChange={({ target: { value: name } }) =>
                    setEditedWhip({ ...editedWhip, name })
                  }
                  value={editedWhip.name}
                ></input>
                <label className="block">
                  <span>Make</span>
                  <input
                    type="text"
                    onChange={({ target: { value: make } }) =>
                      setEditedWhip({
                        ...editedWhip,
                        details: { ...editedWhip.details, make },
                      })
                    }
                    value={editedWhip.details.make}
                  />
                </label>
                <label className="block">
                  <span>Model</span>
                  <input
                    type="text"
                    onChange={({ target: { value: model } }) =>
                      setEditedWhip({
                        ...editedWhip,
                        details: { ...editedWhip.details, model },
                      })
                    }
                    value={editedWhip.details.model}
                  />
                </label>
                <label className="block">
                  <span>Year</span>
                  <input
                    type="number"
                    onChange={({ target: { value: year } }) =>
                      setEditedWhip({
                        ...editedWhip,
                        details: { ...editedWhip.details, year },
                      })
                    }
                    value={editedWhip.details.year}
                  />
                </label>
                <textarea
                  className="my-4 p-4 w-full bg-gray-100"
                  onChange={({ target: { value } }) =>
                    setEditedWhip(() => ({
                      ...editedWhip,
                      details: {
                        ...editedWhip.details,
                        description: value,
                      },
                    }))
                  }
                  value={editedWhip?.details?.description}
                ></textarea>
              </>
            ) : (
              <>
                <div className="mb-4">
                  <Button onClick={() => setEditing(true)}>Update Whip</Button>
                </div>
                <Link
                  className="text-sm text-gray-600"
                  to={`/users/${data?.whip.user.username}`}
                >
                  @{data?.whip.user.username}
                </Link>
                <h1 className="text-2xl leading-tight font-bold">
                  {data?.whip.name}
                </h1>
                <div className="mb-4 text-gray-700">
                  {data?.whip?.details?.make} {data?.whip?.details?.model}{' '}
                  {data?.whip?.details?.year}
                </div>
                <div>{data?.details?.description}</div>
              </>
            )}
          </div>
        </header>
        <WhipHistoryList
          loading={loading}
          data={data?.whip.history.map(({ whip, ...item }: WhipHistory) => ({
            ...item,
          }))}
        />
      </article>
    </>
  )
}

export default WhipProfile
