import React, { createContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { api } from '../api'
import localForage from 'localforage'
import { GET_LOGGED_IN_USER_QUERY } from '../queries'
import { WhipHistory } from '../types/Whip'

export interface LoggedInUser {
  id?: number
  username?: string
  firstName?: string
  lastName?: string
  email?: string
  whips?: any[]
  accessToken?: string
  whipHistory?: WhipHistory[]
}

export const initialUserState = {
  username: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  whips: [],
  accessToken: undefined,
}

export const UserContext = createContext<any>({
  ...initialUserState,
})

export default function useLogin() {
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const { client, loading, error, data } = useQuery(GET_LOGGED_IN_USER_QUERY)

  const loginWithPassword = async (username: string, password: string) => {
    try {
      const {
        data: { accessToken },
      }: any = await api.post('auth/login', {
        username,
        password,
      })

      setAccessToken(accessToken)
    } catch (e) {
      console.error('Login failed.', e)
    }
  }

  const loginWithToken = async (facebookAccessToken: string) => {
    try {
      const {
        data: { accessToken },
      }: any = await api.post('auth/login-with-facebook', {
        accessToken: facebookAccessToken,
      })

      setAccessToken(accessToken)
    } catch (e) {
      console.error('Login failed.', e)
    }
  }

  const logout = async (event: any) => {
    await localForage.removeItem('token')
    setAccessToken(null)
    await client.clearStore()
  }

  const getExistingToken = async (): Promise<string | null> => {
    return await localForage.getItem('token')
  }

  useEffect(() => {
    ;(async () => {
      const existingToken = await getExistingToken()
      existingToken && setAccessToken(existingToken)
    })()
  }, [])

  useEffect(() => {
    accessToken && localForage.setItem('token', accessToken)
    client.refetchQueries({ include: [GET_LOGGED_IN_USER_QUERY] })
  }, [client, accessToken])

  return {
    loginWithPassword,
    loginWithToken,
    logout,
    loggedIn: !!accessToken,
    user: (accessToken && data?.loggedInUser) || {},
    loadingUser: loading,
    accessToken,
    setAccessToken,
  }
}
