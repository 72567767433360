import React, { FC, PropsWithChildren } from 'react'
import { useHistory } from 'react-router-dom'
import tw from 'twin.macro'

const buttonStyle =
  'inline-flex p-2 bg-green-400 font-bold text-green-800 text-sm rounded-sm cursor-pointer shadow'

export const Button = tw.button`${buttonStyle}`

export const LinkButton: FC<PropsWithChildren<any>> = ({
  children,
  to,
  ...props
}) => {
  const history = useHistory()

  return (
    <a
      {...props}
      onClick={(event) => {
        event.preventDefault()
        to.match(/https?/g) ? (window.location = to) : history.push(to)
      }}
      className={buttonStyle}
    >
      {children}
    </a>
  )
}

export const DeleteButton = tw(Button)`bg-red-400 text-red-900`
